.App {
  text-align: center;
}

input {
  -webkit-appearance: none;
  appearance: none;
}

.main {
  min-height: 100vh;
  min-height: 100%;
}

@media screen and (max-width: 992px) {
  .centered {
    text-align: center !important;
  }
  .border {
    border: 1px solid lightgrey;
    margin: 1rem 1rem;
    border-radius: 5px;
  }
}

@media screen and (max-width: 992px) {
  .centered {
    text-align: center !important;
  }
}

.center {
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  .hide {
    display: none;
  }
}

.right-align {
  text-align: end;
  font-weight: bold;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top-bar {
  height: 100px;
}

.navbar {
  height: 100px;
  font-size: 30px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
}

.card {
  margin: 0 5px;
  height: -webkit-fill-available;
}

.receipts-body {
  background-color: var(--green-color);
  font-weight: bold;
}
.rec-item-wrapper {
  height: 100% !important;
}

.carousel-item::after {
  border: rgb(19, 18, 18);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-caption {
  font-size: 40px;
  background: rgba(236, 236, 236, 0.9);
}

.navItems {
  padding-right: 20px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
}
.card-title {
  font-size: 1rem !important;
}

.card-body {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-footer {
  font-size: 0.8rem;
}

.lettuce {
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.rec.rec-arrow {
  color: var(--green-color);
  background-color: white;
}

.rec.rec-arrow:disabled {
  visibility: hidden;
}

.rec .rec-dot_active {
  background-color: var(--green-color);
  box-shadow: 0 0 1px 3px rgb(30, 122, 1);
}

.rec.rec-arrow:hover {
  color: white !important;
  background-color: green !important;
}

.header {
  color: white;
  font-size: 100px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.ShopNowButton {
  height: 80px;
  width: 300px;
}
